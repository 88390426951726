export default{
    data(){
        return{

        }
    },
    methods:{
        onConfirm(){
            this.$router.go(-1)
        }
    }
}